<template>
  <div>
    <div
      v-if="loading !== 'error'"
    >
      <div
        v-if="loading"
        key="1"
        class="d-flex align-items-center justify-content-center"
        style="height: 80vh"
      >
        <b-spinner variant="primary" />
      </div>
      <div
        v-else
        key="2"
      >
        <back-navigation title="Detail Customer">
          <template
            v-if="customerDetail.isAssigned || $root.role === 2"
            #action
          >
            <div class="d-flex justify-content-end ml-auto">
              <b-button
                v-if="id.startsWith('C') && ($root.role === 2 || $root.role === 3)"
                v-b-popover.hover.top="'Customer ini belum ada di Accurate, klik tombol ini untuk menambah ke Accurate'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-danger"
                class="mr-1"
                @click="addCustomerToAccurate()"
              >
                Tambah ke Accurate
              </b-button>
              <b-button
                v-if="id.startsWith('C')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :to="{ name: 'customer-edit', params: { id: id }}"
                variant="primary"
                class="ml-auto"
              >
                Edit
              </b-button>
            </div>
          </template>
        </back-navigation>

        <XyzTransition
          appear
          xyz="fade left-3 delay-5"
        >
          <div>
            <b-card>
              <b-row>
                <b-col
                  cols="21"
                  xl="4"
                  class="mb-1 mb-md-0"
                >
                  <div class="d-flex justify-content-start">
                    <b-avatar
                      :text="avatarText(customerDetail.name)"
                      variant="light-primary"
                      size="80px"
                      rounded
                    />
                    <div class="d-flex flex-column ml-1 mb-50">
                      <h4 class="mb-0">
                        {{ customerDetail.name }}
                      </h4>
                      <h6 class="text-muted m-0 mt-25">
                        {{ customerDetail.customerNo }}
                      </h6>
                      <span
                        v-if="customerDetail.email"
                        class="card-text mt-50"
                      ><feather-icon
                        icon="MailIcon"
                        class="mr-50 mb-25 text-muted"
                      />{{ customerDetail.email }}</span>
                      <span
                        v-if="customerDetail.mobilePhone"
                        class="card-text"
                      ><feather-icon
                        icon="PhoneIcon"
                        class="mr-50 mb-25 text-muted"
                      />{{ customerDetail.mobilePhone }}</span>
                    </div>
                  </div>
                  <div class="d-flex mb-25 mt-1">
                    <b-badge
                      v-if="customerDetail.category"
                      v-b-popover.hover.top="'Kategori'"
                      class="mr-50"
                      variant="light-primary"
                    >
                      {{ customerDetail.category.name }}
                    </b-badge>
                    <b-badge
                      v-if="customerDetail.priceCategory"
                      v-b-popover.hover.top="'Kategori Harga'"
                      variant="light-info"
                    >
                      {{ customerDetail.priceCategory.name }}
                    </b-badge>
                    <b-badge
                      v-if="customerDetail.status"
                      v-b-popover.hover.top="'Status Customer'"
                      :variant="customerDetail.status === 0 ? 'secondary' : 'primary'"
                      class="mr-50 ml-50"
                    >
                      {{ customerDetail.status === 0 ? 'Tidak Aktif' : 'Aktif' }}
                    </b-badge>
                  </div>
                  <div
                    v-if="salesAssigned.length"
                    class="mt-50"
                  >
                    <small class="text-muted">Sales Assigned</small>
                    <b-avatar-group
                      size="40px"
                      class="mt-25 mb-0 mb-md-1"
                    >
                      <b-avatar
                        v-for="sales in salesAssigned"
                        :key="sales.id"
                        v-b-tooltip.hover
                        class="pull-up custom-avatar"
                        :title="sales.name"
                        :text="avatarText(sales.name)"
                        button
                        :src="sales.photo && sales.photo !== 'default.png' ?
                          `${$restHTTP}/assets/img/profile-picture/${sales.id}/${sales.photo}` :
                          `${$restHTTP}/assets/img/profile-picture/default.png`
                        "
                        @click="$router.push({ name: 'user-detail', params: {id: sales.id.toString()} })"
                      />
                    </b-avatar-group>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  xl="4"
                  class="mb-1 mb-md-0"
                >
                  <customer-performance
                    :id="id"
                    class="mt-1 mt-lg-0"
                  />
                </b-col>
                <b-col
                  cols="12"
                  xl="4"
                >
                  <div
                    class="mb-50"
                  >
                    <small class="text-muted">
                      <feather-icon
                        icon="HashIcon"
                        class="mr-25 mb-25"
                      />
                      NPWP
                    </small>
                    <h6
                      v-if="customerDetail.npwpNo"
                      class="m-0"
                    >
                      {{ customerDetail.npwpNo }}
                    </h6>
                    <small
                      v-else
                      class="text-muted font-italic d-block"
                    >
                      Belum diisi
                    </small>
                  </div>
                  <div
                    class="mb-50"
                  >
                    <small class="text-muted">
                      <feather-icon
                        icon="HomeIcon"
                        class="mr-25 mb-25"
                      />
                      Alamat
                    </small>
                    <h6
                      v-if="customerDetail.billStreet"
                      class="m-0"
                    >
                      {{ customerDetail.billStreet }}{{ customerDetail.city ? `, ${customerDetail.city}` : '' }}{{ customerDetail.province ? `, ${customerDetail.province}.` : '.' }}
                    </h6>
                    <small
                      v-else
                      class="text-muted font-italic d-block"
                    >
                      Belum diisi
                    </small>
                  </div>
                  <div
                    class="mb-50"
                  >
                    <small class="text-muted d-block">
                      <feather-icon
                        icon="DollarSignIcon"
                        class="mr-25 mb-25"
                      />
                      Payment Term
                    </small>
                    <b-badge v-if="customerDetail.term">
                      {{ customerDetail.term.name }}
                    </b-badge>
                    <small
                      v-else
                      class="text-muted font-italic d-block"
                    >
                      Belum diisi
                    </small>
                  </div>
                  <div
                    class="mb-50"
                  >
                    <small class="text-muted">
                      <feather-icon
                        icon="TagIcon"
                        class="mr-25 mb-25"
                      />
                      Default Discount
                    </small>
                    <h6
                      v-if="customerDetail.defaultSalesDisc"
                      class="m-0"
                    >
                      {{ customerDetail.defaultSalesDisc }}
                    </h6>
                    <small
                      v-else
                      class="text-muted font-italic d-block"
                    >
                      Belum diisi
                    </small>
                  </div>
                  <div
                    class="mb-50"
                  >
                    <small class="text-muted">
                      <feather-icon
                        icon="CreditCardIcon"
                        class="mr-25 mb-25"
                      />
                      Limit Piutang
                    </small>
                    <div v-if="customerDetail.customerLimitAmountValue">
                      <div class="d-flex justify-content-between">
                        <small
                          v-b-popover.hover.top="`Sisa piutang : ${formatCurrency(customerDetail.saldoPiutang)}`"
                          class="m-0"
                        >
                          {{ shortNumber(customerDetail.saldoPiutang) }}
                        </small>
                        <small
                          v-b-popover.hover.top="`Limit piutang : ${formatCurrency(customerDetail.customerLimitAmountValue)}`"
                          class="m-0"
                        >
                          {{ shortNumber(customerDetail.customerLimitAmountValue) }}
                        </small>
                      </div>
                      <b-progress
                        class="mt-50"
                        :value="customerDetail.saldoPiutang"
                        :max="customerDetail.customerLimitAmountValue"
                      />
                    </div>
                    <div v-else>
                      <div class="d-flex justify-content-between">
                        <small>Balance: </small>
                        <small>{{ shortNumber(customerDetail.saldoPiutang) }}</small>
                      </div>
                      <b-progress
                        v-b-popover.hover.top="'Customer ini tidak punya limit, sehingga Sisa piutang tidak terbatas.'"
                        class="mt-50"
                        :value="100"
                        :max="100"
                      />
                    </div>
                    <!-- <div v-if="customerDetail.customerLimitAmountValue !== null">
                      <div class="d-flex justify-content-between">
                        <h6
                          v-b-popover.hover.top="`Sisa piutang : ${formatCurrency(customerDetail.saldoPiutang)}`"
                          class="m-0"
                        >
                          {{ shortNumber(customerDetail.saldoPiutang) }}
                        </h6>
                        <h6
                          v-b-popover.hover.top="`Limit piutang : ${formatCurrency(customerDetail.customerLimitAmountValue)}`"
                          class="m-0"
                        >
                          {{ shortNumber(customerDetail.customerLimitAmountValue) }}
                        </h6>
                      </div>
                      <b-progress
                        class="mt-50"
                        :value="customerDetail.saldoPiutang"
                        :max="customerDetail.customerLimitAmountValue"
                      />
                    </div>
                    <small
                      v-else
                      class="text-muted font-italic d-block"
                    >
                      Belum diisi
                    </small> -->
                  </div>
                </b-col>
              </b-row>
            </b-card>
            <b-card>
              <customer-chart
                :customer-id="id"
                :sales="salesAssigned.filter(el => el.id !== $store.getters.getCurrentUser.user.id)"
              />
            </b-card>
            <b-row class="match-height">
              <b-col
                cols="12"
                xl="7"
              >
                <b-card no-body>
                  <div
                    class="overflow-auto px-2"
                    style="max-height: 80vh"
                  >
                    <vue-perfect-scrollbar
                      :settings="{
                        maxScrollbarLength: 300,
                      }"
                      class="list-group scroll-area overflow-hidden pt-2 pb-2"
                    >
                      <b-tabs>
                        <b-tab title="Quotation">
                          <b-overlay :show="loadingQuote">
                            <quotations-table
                              :data="quoteList"
                              :count="quoteList.length"
                              :per-page="25"
                            />
                          </b-overlay>
                        </b-tab>
                        <b-tab title="Project">
                          <b-row class="mb-1 align-items-baseline">
                            <b-col
                              cols="12"
                              sm="7"
                              class="mb-1"
                            >
                              <v-select
                                v-if="projectView === 'table'"
                                v-model="selectedSort"
                                class="mr-1 w-100"
                                label="label"
                                :options="sortOptions"
                                placeholder="Sortir"
                                :clearable="false"
                                @option:selected="fetchProjectCustomer()"
                              />
                            </b-col>
                            <b-col
                              cols="12"
                              sm="4"
                              class="ml-auto"
                            >
                              <div class="d-flex justify-content-center justify-content-sm-end">
                                <b-form-radio-group
                                  v-model="projectView"
                                  class="list item-view-radio-group w-100 w-sm-auto"
                                  buttons
                                  size="sm"
                                  button-variant="outline-primary"
                                >
                                  <b-form-radio
                                    value="table"
                                  >
                                    <feather-icon
                                      icon="ListIcon"
                                      size="18"
                                    />
                                  </b-form-radio>
                                  <b-form-radio
                                    value="calendar"
                                  >
                                    <feather-icon
                                      icon="CalendarIcon"
                                      size="18"
                                    />
                                  </b-form-radio>
                                </b-form-radio-group>
                              </div>
                            </b-col>
                          </b-row>
                          <b-overlay
                            v-if="projectView === 'table'"
                            :show="loadingProject"
                          >
                            <project-table
                              :data="projectList"
                              :count="projectList.length"
                              :per-page="25"
                            />
                          </b-overlay>
                          <div v-else>
                            <full-calendar :options="calendarOptions" />
                          </div>
                        </b-tab>
                        <b-tab title="Sales Order">
                          <b-overlay :show="loadingSalesOrder">
                            <sales-order-table
                              :data="salesOrderList"
                              :count="salesOrderList.length"
                              is-customer
                            />
                          </b-overlay>
                        </b-tab>
                        <template #tabs-end>
                          <div class="d-flex justify-content-end flex-fill">
                            <b-dropdown
                              v-if="customerDetail.isAssigned || $root.role === 2"
                              variant="link"
                              no-caret
                              toggle-class="p-0"
                              right
                              class="ml-auto"
                            >
                              <template #button-content>
                                <feather-icon
                                  icon="MoreVerticalIcon"
                                  size="16"
                                  class="align-middle text-body"
                                />
                              </template>
                              <b-dropdown-item
                                v-if="customerDetail.isAssigned || $root.role === 2"
                                :to="{ name: 'addquote', params: { selectedCustomer: customerDetail, resetFormState: true } }"
                              >
                                <feather-icon
                                  icon="FileTextIcon"
                                  class="mr-50 mb-25 text-primary"
                                />
                                <span class="text-primary">Buat Quote</span>
                              </b-dropdown-item>
                              <b-dropdown-item
                                v-if="customerDetail.isAssigned || $root.role === 2"
                                :to="{ name: 'addproject', params: { selectedCustomer: customerDetail } }"
                              >
                                <feather-icon
                                  icon="HomeIcon"
                                  class="mr-50 mb-25 text-info"
                                />
                                <span class="text-info">Buat Project</span>
                              </b-dropdown-item>
                              <b-dropdown-item
                                v-if="customerDetail.isAssigned || $root.role === 2"
                                :to="{ name: 'salesorder-add', params: { selectedCustomer: customerDetail } }"
                              >
                                <feather-icon
                                  icon="TagIcon"
                                  class="mr-50 text-warning"
                                />
                                <span class="text-warning">Buat Sales Order</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </template>
                      </b-tabs>
                    </vue-perfect-scrollbar>
                  </div>
                </b-card>
              </b-col>
              <b-col
                cols="12"
                xl="5"
              >
                <b-card
                  v-if="customerDetail"
                  no-body
                >
                  <div
                    class="overflow-auto px-2"
                    style="max-height: 80vh"
                  >
                    <vue-perfect-scrollbar
                      :settings="{
                        maxScrollbarLength: 300,
                      }"
                      class="list-group scroll-area overflow-hidden pt-2 pb-2"
                    >
                      <daily-report
                        :id="id"
                        :customer="customerDetail"
                        type="customer"
                      />
                    </vue-perfect-scrollbar>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </div>
        </XyzTransition>

      </div>
    </div>
    <error-endpoint
      v-else
      :key="error"
      ref="error"
      :error="error"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BBadge, BTab, BTabs, BSpinner, BAvatar, VBPopover, BOverlay,
  BFormRadioGroup, BFormRadio, BProgress, BAvatarGroup, VBTooltip, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { avatarText, formatCurrency, shortNumber } from '@core/utils/filter'
import ProjectTable from '@/views/project/ProjectTable.vue'
import QuotationsTable from '@/views/quotation/QuotationsTable.vue'
import SalesOrderTable from '@/views/salesorder/SalesOrderTable.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BackNavigation from '@/components/misc/BackNavigation.vue'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import idLocale from '@fullcalendar/core/locales/id'
import ErrorEndpoint from '@/components/misc/ErrorEndpoint.vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { deleteCustomer } from '@/graphql/mutation'
import DailyReport from '../../components/misc/DailyReport.vue'
import CustomerPerformance from './CustomerPerformance.vue'
import CustomerChart from './CustomerChart.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BTab,
    BTabs,
    BSpinner,
    BOverlay,
    BFormRadioGroup,
    BFormRadio,
    BProgress,
    BAvatar,
    BAvatarGroup,
    BDropdown,
    BDropdownItem,
    ProjectTable,
    QuotationsTable,
    SalesOrderTable,
    DailyReport,
    CustomerPerformance,
    BackNavigation,
    FullCalendar,
    ErrorEndpoint,
    CustomerChart,
    vSelect,
    VuePerfectScrollbar,
  },
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      customerDetail: '',
      loadingQuote: true,
      quoteList: [],
      loadingProject: true,
      projectList: [],
      loadingSalesOrder: true,
      salesOrderList: [],
      projectView: 'table',
      salesAssigned: [],
      error: '',
      selectedSort: {
        label: 'Sortir',
        field: 'id',
        sortType: 'asc',
      },
    }
  },
  computed: {
    ...mapGetters({
      sortOptions: 'getSortOptions',
    }),
    calendarEvents() {
      return this.projectList.map(el => ({
        id: el.id,
        title: el.name,
        date: new Date(el.tgl_reminder),
        allDay: true,
        backgroundColor: this.eventColor(el).backgroundColor,
        textColor: this.eventColor(el).textColor,
        borderColor: this.eventColor(el).borderColor,
      }))
    },
    calendarOptions() {
      return {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          start: 'prev,next, title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        events: this.calendarEvents,
        locale: idLocale,
        eventClick: this.handleEventClick,
      }
    },
  },
  created() {
    this.getCustomerDetail()
    this.$store.dispatch('getQuotesCustomer', { id: this.id }).then(result => {
      this.loadingQuote = false
      this.quoteList = result
    })
    this.fetchProjectCustomer()

    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(+this.id)) {
      this.$store.dispatch('getSalesOrder', {
        promise: true,
        filter: {
          customer_id: +this.id,
        },
        pagination: {
          limit: 25,
          offset: 1,
        },
      }).then(result => {
        this.salesOrderList = result.data.salesOrder.sales_order
        this.loadingSalesOrder = false
      })
    } else {
      this.loadingSalesOrder = false
    }
  },
  methods: {
    fetchProjectCustomer() {
      this.loadingProject = true
      this.$store.dispatch('getProjectCustomer', {
        id: this.id,
        sort: this.selectedSort.field,
        sortType: this.selectedSort.sortType,
      }).then(result => {
        this.loadingProject = false
        this.projectList = result
      })
    },
    async getCustomerDetail() {
      this.loading = true
      this.$store.dispatch('getCustomerDetail', this.id).then(result => {
        this.customerDetail = result

        this.$store.dispatch('getSalesAssigned', { id: this.id }).then(sales => {
          this.salesAssigned = sales
          this.loading = false
        })
      }).catch(error => {
        this.loading = 'error'
        this.error = `${error.graphQLErrors[0].message}`
      })
    },
    deleteCustomer() {
      this.$swal({
        title: 'Hapus Data Customer?',
        text: 'Konfirmasi jika anda ingin menghapus data customer',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await this.$apollo.mutate({
            mutation: deleteCustomer,
            context: {
              headers: {
                Authorization: this.$store.getters.getBearerToken,
              },
            },
            variables: {
              customer_id: this.id,
            },
          }).then(value => {
            if (value.data.deleteCustomer.status === 'Success') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil menghapus data customer',
                  icon: 'TrashIcon',
                  variant: 'success',
                },
              })
              this.$router.push({ name: 'customers' })
            }
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Gagal menghapus data customer',
                icon: 'TrashIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
    eventColor(data) {
      const twoWeeksBefore = new Date(Date.now() - 12096e5)
      const twoWeeksAfter = new Date(Date.now() + 12096e5)
      const reminderDate = new Date(data.tgl_reminder)

      if (data.status === 0) {
        return {
          backgroundColor: 'rgba(234,84,85,.12)',
          textColor: '#EA5457',
          borderColor: 'rgba(234,84,85,.12)',
        }
      }

      if (reminderDate >= twoWeeksBefore && reminderDate <= twoWeeksAfter) {
        return {
          backgroundColor: 'rgba(40,199,111,.12)',
          textColor: '#28c76f',
          borderColor: 'rgba(40,199,111,.12)',
        }
      }

      return {
        backgroundColor: 'rgba(255,159,67,.12)',
        textColor: '#ff9f43',
        borderColor: 'rgba(255,159,67,.12)',
      }
    },
    handleEventClick(data) {
      this.$router.push({ name: 'project-detail', params: { id: data.event.id.toString() } })
    },
    addCustomerToAccurate() {
      this.$swal({
        title: 'Tambah Customer?',
        text: 'Konfirmasi jika anda ingin menambah customer ke Accurate',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('addCustomerAccurate', this.id).then(data => {
            if (data.data.addCustomerAccurate.status.toLowerCase() === 'success') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil menambah customer ke Accurate!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.$router.replace({ name: 'customer-detail', params: { id: data.data.addCustomerAccurate.id.toString() } })
            }
          })
        }
      })
    },
  },
  setup() {
    return {
      avatarText,
      formatCurrency,
      shortNumber,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
@import '@core/scss/vue/libs/vue-select.scss';

.custom-avatar {
  margin-left: calc(-2px) !important;
  margin-right: calc(-2px) !important;
}

.fc .fc-day-today {
  .dark-layout & {
    background: #364B46 !important;
  }
}

.fc .fc-scrollgrid {
  border-bottom-width: 1px !important;
  border-radius: 4px;
}
</style>
